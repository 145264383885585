// Color
// $gradient: (#860c0a, #c3251f, #c3251f);
// $medium-red:#c3251f;
// $light-red: rgba(195, 37, 31, 0.3);

$main-red: rgba(195, 37, 31, 1);
$text-gray: rgba(51, 51, 51, 1);
$rose: rgba(255, 153, 153, 1);
$hover-dark-red: #66181c;
$main-blue: #201d5c;
$main-orange: #d44e27;
$main-yellow: #ea9600;
// Font size

$ERROR_RED: #ff6e6e !default;
$GREEN_COLOR: #3fd184 !default;
$MAIN_COLOR: #ad8a4e !default;
$DARKER_MAIN_COLOR: #0f5ef6 !default;
$MAIN_LIGHT_COLOR: #11befe !default;
$MAIN_DARK_COLOR: #383838 !default;
$FOOTER_BACKGROUND_COLOR: #383838 !default;
$GRAY_COLOR: #c2c2c2 !default;
$CHART_START_COLOR: #622aae !default;
