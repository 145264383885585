@import './variables.scss';
@import './pdl-styles.scss';
@import '../assets/icons/style.css';

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/OpenSans-Regular.ttf');
}
@font-face {
  font-family: 'OpenSans';
  font-style: bold;
  font-weight: 900;
  src: url('./fonts/OpenSans-Bold.ttf');
}
@font-face {
  font-family: 'PlayfairDisplay';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/PlayfairDisplay-Regular.ttf');
}
@font-face {
  font-family: 'PlayfairDisplay';
  font-style: bold;
  font-weight: 900;
  src: url('./fonts/PlayfairDisplay-Bold.ttf');
}

$normal: 'OpenSans';
$bold: 'OpenSans';
$font-sarif: 'PlayfairDisplay';
a {
  outline: none !important;
}
.circle-progress {
  color: white !important;
}
// PAGE TRANSITION ANIMATIONS

.switch-wrapper {
  position: relative;
  display: block !important;
  max-width: 100%;
  padding-bottom: 30px;
  min-height: 500px;
  &.card-switch {
    @media (max-width: 768px) {
      min-height: 720px;
    }
  }
  &.account {
    min-height: 720px;
    @media (max-width: 992px) {
      min-height: 860px;
    }
    @media (max-width: 768px) {
      min-height: 920px;
    }
    @media (max-width: 545px) {
      min-height: 1380px;
    }
  }
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  margin: auto;
}

.gradient {
  // background: linear-gradient(#860c0a, #c3251f, #c3251f);
  background: #d44e27; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #d44e27 0%,
    #ea9600 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #d44e27 0%,
    #ea9600 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #d44e27 0%,
    #ea9600 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d44e27', endColorstr='#ea9600',GradientType=0 ); /* IE6-9 */
}
.breadcrumb {
  padding: 0;
  margin-bottom: 30px;
  margin-top: 10px;
  a.breadcrumb-item {
    color: $main-blue;
    &:last-child {
      color: $main-orange;
    }
    &:first-child::before {
      content: url('../assets/img/breadcrumb-arrow-back.svg');
      padding-right: 5px;
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    color: #6c757d;
    width: 7px;
    padding: 0;
    content: ' ';
    height: 7px;
    border-radius: 50%;
    background: #6c757d;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-left: 5px;
  }
}

input {
  &:before {
    border-bottom: none !important;
  }
  &:after {
    border-bottom: none !important;
  }
}
input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='tel'],
input[type='date'],
textarea {
  // color: $main-red !important;
  font-weight: 300;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  // color: $main-red !important;
  color: black !important;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  transition: background-color 5000s ease-in-out 0s;
  color: black !important;
}
.tabletOverlay {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  z-index: 999999999999;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    // display: flex;
  }
  .tabletMessage {
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px;
    position: fixed;
    p {
      font-size: 14px;
    }
  }
}
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'OpenSans';
  // overflow-x: hidden !important;
  @media (max-width: 1000px) {
    // overflow: hidden!important;
  }
}
body {
  width: 100%;
  margin: 0;
  padding: 0;
  // overflow-x: hidden !important;
  font-family: 'OpenSans';
  background: #f9f9f9;
  max-width: 100%;
  @media (max-width: 1000px) {
    // overflow: hidden!important;
  }
}
.squaredTwo {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fff;
  border: 1px solid $dark-gray;
  // box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0,0,0,0.5);
  label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    left: 4px;
    top: 4px;
    // background: #fff;
    color: $dark-gray;
    &:after {
      content: '';
      width: 14px;
      height: 8px;
      position: absolute;
      top: 3px;
      left: 2px;
      transition: opacity 0.3s ease;
      border: 3px solid $gold;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
    &:hover::after {
      opacity: 0.3;
    }
  }
  input[type='checkbox'] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}
body.ReactModal__Body--open {
  overflow: hidden;
  padding-right: 17px;
}
.ReactModal__Body--open {
  overflow-y: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
}
a:hover {
  text-decoration: none;
}
.dropdown-toggle::after {
  display: none;
}
select::after {
  display: none;
}
.fiter-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.shortMenuWrapper {
  display: flex;
  .topNotificationButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .mainIcon {
      color: $rose;
      font-size: 32px;
      padding: 0 10px;
    }
    .notificationNumber {
      color: white;
      background-color: $main-red;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
    }
  }
  .topUserButton {
    cursor: pointer;
    #user-drop {
      transform: translate3d(-76px, 20px, 0px) !important;
      border-radius: 0;
      border: 1px solid black;
      padding: 15px;
      ul {
        margin: 0;
        li {
          a {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.7);
            width: 100%;
            display: flex;
            flex: 1;
          }
        }
        li:last-child {
          padding-top: 15px;
        }
      }
    }
  }
}

// About
.about {
  h1 {
    color: $gold;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 50px;
  }
  p {
    color: #343434;
    font-weight: lighter;
    text-align: left;
    padding: 10px 100px;
    font-size: 16px;
    @media (max-width: 991px) {
      padding: 10px 50px;
    }
    @media (max-width: 768px) {
      padding: 10px;
    }
  }
}
.terms {
  h1 {
    color: $gold;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 50px;
  }
  p {
    color: #343434;
    font-weight: lighter;
    text-align: left;
    padding: 10px 30px;
    font-size: 16px;
    @media (max-width: 991px) {
      padding: 10px 20px;
    }
    @media (max-width: 768px) {
      padding: 10px;
    }
  }
}
// Dashboard
.topUp-slidecontainer {
  max-width: 80%;
  width: 100%;
  margin: auto;
  display: block;
  .slider.slider-horizontal {
    margin: 0 auto;
  }

  .slider.slider-horizontal .slider-track {
    background-color: $main-red;
  }

  .load-wrapper {
    position: relative;
    margin-bottom: 50px;

    .validation-message {
      width: 100%;
      text-align: center;
    }
  }

  .load-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: auto;
    align-items: center;
    > div {
      background: white;
    }
    input {
      flex: 1;
      margin: auto;
      min-width: 150px;
      width: 100%;
      text-align: center;
      background: white;
      padding: 10px 25px;
      font-size: 20px;
      color: $dark-gray;
      border: 1px solid $dark-gray;
      box-shadow: none;
      outline: none;
      align-self: flex-end;
      font-weight: bold;
    }
    span {
      color: $dark-gray;
      font-weight: bold;
      margin: 0 20px 0 0;
    }
    p {
      position: absolute;
      top: 11px;
      left: 15px;
      font-size: 20px;
      font-weight: bold;
      color: $dark-gray;
    }
  }
}
.Collapsible {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid gray;
  padding-bottom: 20px;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  span {
    color: $MAIN_COLOR;
  }
  .full-text {
    font-weight: 300;
  }
}
.filter-dropdown {
  padding: 0;
  list-style-type: none;
  li {
    font-size: 14px;
  }
}
.topUpModal {
  margin: -10px;
  padding: 30px;
  min-width: 500px;
  h1 {
    color: white;
    text-align: center;
    font-size: 42px;
    font-weight: 300;
    margin-bottom: 30px;

    span {
      font-weight: 300;
      font-size: 14px;
      text-align: center;
      display: block;
    }
  }
  .slider-wrapper {
    background: white;
    padding: 20px;
  }
}
.order-card-modal {
  max-width: 500px;
  padding: 30px;
  text-align: center;
  background: white;
  border-radius: 5px;
  @media (max-width: 545px) {
    max-width: 100%;
  }
  h2 {
    color: $main-red;
    text-align: center;
    font-weight: 400;
    margin-bottom: 30px;
  }
  p {
    color: $text-gray;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
    span {
      font-weight: bold;
    }
  }
  img {
    max-width: 70%;
    display: block;
    margin: auto;
  }
  .passcode-text {
    color: #d44e27;
    font-weight: bold;
    font-size: 16px;
  }
  label {
    margin-top: 30px;
  }
}
.input-wrapper {
  max-width: 254px;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .input-pin {
    max-width: 15%;
    flex: 1;
    border: none;
    background: transparent;
    border-bottom: 2px solid $dark-gray;
    text-align: center;
    font-size: 24px;
    color: $dark-gray;
    outline: none;
    border-radius: 0;
  }
}
.filter-drop {
  transform: translate3d(-300px, 50px, 0px) !important;
  border-radius: 0;
  border: 1px solid black;
  padding: 15px;
}
.arrow-up {
  width: 20px;
  height: 5px;
  border-bottom: solid 10px black;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.cover-arrow {
  width: 20px;
  height: 5px;
  border-bottom: solid 10px white;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  position: absolute;
  left: 0;
  top: 1px;
}
.slidecontainer {
  width: 100%;
  padding: 40px 0px 0 0px;
  min-width: 325px;
  display: flex;
  .plusMinusHolder {
    display: flex;
    .plus-minus {
      border: 1px solid rgba(0, 0, 0, 0.5);
      background-color: $main-red;
      color: white;
      width: 15px;
      height: 15px;
      margin: 0 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      cursor: pointer;
      line-height: 1px;
      outline: none !important;
      &:hover {
        text-decoration: none;
      }
      span {
        height: 20px;
      }
    }
  }
}
#ex1 {
  height: 55px;
  min-width: 265px;
  margin: 0 21px;
}
.slider.slider-horizontal {
  height: 60px;
  margin: 0 20px;
}
.slider-handle {
  width: 30px !important;
  height: 30px !important;
  background-color: #fff !important;
  background-image: none !important;
  background-image: none !important;
  top: -10px !important;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.5) !important;
}
.slider-track {
  height: 1px !important;
}
.slider-selection {
  box-shadow: none;
  background-image: none;
}
.tooltip-min,
.tooltip-max {
  display: none !important;
  opacity: 0;
}
.tooltip {
  opacity: 1 !important;
  .tooltip-inner {
    width: 80px;
    padding: 0.25rem 0.5rem;
    margin-left: 10px;
    color: $main-red;
    text-align: center;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 0;
    &::after {
      content: '';
      position: fixed;
      top: 29px;
      left: 40px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid white;
    }
    &::before {
      content: '';
      position: fixed;
      top: 30px;
      left: 40px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid black;
    }
  }
}
.navbar-nav {
  position: relative;
  z-index: 100;
  .orange {
    background: #d44e27;
    border-radius: 50px;
    &:hover {
      background: #ea9600;
    }
    a {
      color: white;
      text-decoration: none;
    }
  }
  .nav-item {
    margin-right: 10px;
    margin-left: 10px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    border: 1px solid transparent;
    transition: all 0.3 linear;

    a {
      width: 100%;
      text-align: center;
      display: block;
      align-items: center;
      justify-content: center;
    }
    .nav-link {
      padding: 0.5rem;
      text-decoration: none;
      transition: all 0.3 linear;
      cursor: pointer;
      @media (max-width: 1200px) {
        padding: 0.5rem 0.3rem;
      }
    }
  }
}
.input-holder {
  width: 100%;
  background-color: white;
  padding: 5px 10px 5px 10px;
}

.activeLink {
  color: $main-red !important;
}
.MuiInput input {
  color: $main-red !important;
}
.MuiInputBase-input-42 {
  color: $main-red !important;
  font-weight: 300 !important;
}
#log-in {
  // padding: 30px;
  .login-input {
    width: 100%;
    padding: 0 10px;
    height: 40px;
  }
}
.login-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // max-width: 500px;
  padding: 0;
  @media (max-width: 768px) {
    max-width: 100%;
  }
  h2 {
    margin-bottom: 40px;
    font-size: 52px;
  }
  h4 {
    font-size: 32px;
    margin-bottom: 50px;
  }
  h2,
  h4,
  h6,
  a,
  p,
  label {
    font-weight: 300;
  }
}
.signup-wrapper {
  background: url('../assets/img/sign-up-bg.png') no-repeat center center;
  // background-size: cover;
}
.login-image {
  width: 100%;
  height: auto;
}
.form-button-container {
  width: 100%;
}
#sign-up {
  padding: 40px;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  .signUp-input {
    height: 40px;
    margin: 10px 0;
    padding: 10px;
  }
}

.signupInputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
  position: relative;
  flex: 1;
  label {
    flex: 1;
    color: white;
    font-size: 12px;
    font-weight: lighter;
    text-align: left;
    text-transform: capitalize;
  }
  input {
    flex: 1;
    background: #fff;
    border: none;
    box-shadow: none;
    outline: none;
    height: 40px;
    color: $main-red !important;
    padding-left: 10px;
  }
  input[type='date'] {
    height: auto !important;
    padding: 15px 0 15px 10px;
  }
}

.passwordInfo {
  background-color: white;
  position: absolute;
  left: 0;
  top: 75px;
  z-index: 999;
  transition: all 0.3s ease;
}
.amountInfo {
  background-color: white;
  position: absolute;
  left: 0;
  top: 105%;
  z-index: 999;
  margin: 0 15px;
  transition: all 0.3s ease;
  border: 1px solid gray;
  box-shadow: -2px 4px 10px 1px rgba(0, 0, 0, 0.2);
}
.descInfo {
  background-color: white;
  position: absolute;
  left: 0;
  top: 105%;
  z-index: 999;
  margin: 0;
  transition: all 0.3s ease;
  border: 1px solid gray;
  box-shadow: -2px 4px 10px 1px rgba(0, 0, 0, 0.2);
}
.rowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .title {
    width: 100%;
  }
  .first-name,
  .last-name {
    max-width: 45%;
    flex: 1;
    display: flex;
  }
  .address-btn {
    max-width: 40%;
    align-items: center;
    display: flex;
  }
  .post-code,
  .country {
    max-width: 45%;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    .title {
      max-width: 100%;
    }
    .first-name,
    .last-name {
      max-width: 100%;
    }
    .post-code,
    .country {
      max-width: 100%;
    }
  }
}
.homeHeaderWrapper {
  background-image: url('../assets/img/manual-worker.png');
  padding: 50px 0;
  background-color: transparent;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  .homeProfilePicture {
    font-family: Arial, Helvetica, sans-serif;
    width: 150px;
    height: 150px;
    margin: auto;
    background-color: #d44e27;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -3px 5px 10px 1px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    .shortName {
      font-size: 72px;
      color: white;
      margin: 0;
    }
  }
  .lastLogin {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .info {
    padding: 20px 0;
    border-bottom: 1px solid $main-blue;
    @media (max-width: 545px) {
      flex-direction: column;
    }
  }
  .shortInfoApp {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;

    .infoHolder {
      display: flex;
      flex: 1;
      align-items: center;
      i {
        font-size: 26px;
        padding-right: 20px;
        color: white;
      }
      h4 {
        color: white;
      }
      p {
        font-weight: 100;
        font-size: 14px;
        padding: 0 20px;
        color: white;
      }
    }
  }
  .switchHolder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    padding: 10px 0;
  }
}

input.serach-input {
  width: 100%;
  box-shadow: 0;
  outline: none;
  padding: 5px 30px;
  color: rgba(0, 0, 0, 0.7) !important;
}
.no-suggestions {
  color: #999;
  padding: 0.5rem;
  position: absolute;
  top: 35px;
  em {
    color: $main-orange;
  }
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  overflow-y: auto;
  padding-left: 0;
  position: absolute;
  left: 0;
  top: 40px;
  background-color: white;
  width: 100%;
  z-index: 9999;
}

.suggestions li {
  padding: 30px 0;
  margin: 0 1rem;
}

.suggestion-active,
.suggestions li:hover {
  color: $main-red;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.sectionHeader {
  background-color: $main-red;
  padding: 16px 24px;
  margin: 30px 0;
  display: flex;
  align-items: center;
  .sectionTitle {
    padding-left: 21px;
    font-weight: 100;
    color: white;
    @media (max-width: 1200px) {
      font-size: 24px;
    }
  }
}
.card {
  flex: 1;
  min-width: 320px;
  padding: 16px;
  margin: 16px 0;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  // box-shadow:-2px 4px 10px 1px rgba(0,0,0,0.2);
  @media (max-width: 545px) {
    min-width: 100%;
  }
  .cardHeader {
    background-color: rgb(248, 247, 247);
    display: flex;
    align-items: center;
    padding: 16px 24px;
    .cardTitle {
      color: rgba(0, 0, 0, 0.5);
      padding-left: 24px;
      font-size: 24px;
      font-weight: 100;
      @media (max-width: 1200px) {
        font-size: 16px !important;
        padding-left: 10px;
      }
    }
  }
  .blue-text {
    color: $main-blue;
  }
  .cardImage {
    width: 100%;
    height: auto;
    padding: 16px 0;
    max-height: 327px;
    max-width: 445px;
  }

  .info-card-image {
    width: 100%;
    margin: 16px 0;
    background-size: cover;
    background-position: center center;
    height: 330px;

    &.with-margin {
      margin: 16px 0;
      padding: 0;
    }

    &.smaller {
      height: 250px;
    }
  }
  .projectTitle {
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    padding: 16px 0;
    font-size: 24px;
    font-weight: bold;
    @media (max-width: 1200px) {
      font-size: 18px !important;
    }
  }
  .funded {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    h6,
    h5 {
      color: rgba(0, 0, 0, 0.7);
    }
    h3 {
      color: black;
      padding-left: 30px;
      font-weight: bold;
    }
    .current {
      display: flex;
      align-items: center;
    }
  }
  .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
  }
}
.balance {
  position: absolute;
  left: 50%;
  margin-left: -151px;
  top: 80px;
  @media (max-width: 1199px) {
    margin-left: -139px;
  }
}
.orange-button {
  background: $main-orange;
  border-radius: 50px;
  border: none;
  outline: none;
  padding: 10px 50px;
  color: white;
  transition: all 0.3s ease;
  &:hover {
    background: $main-yellow;
    color: white;
  }
  &.small-button {
    // padding: 5px 10px;
    max-width: 180px;
    width: 100%;
    text-align: center;
  }
}

.redButton {
  background-color: $main-red;
  border: none;
  color: white;
  padding: 10px 50px;
  outline: none !important;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none !important;
  transition: all 0.3s linear;
  @media (max-width: 1200px) {
    padding: 10px 30px !important;
  }
  &:hover {
    text-decoration: none !important;
    color: white;
    background-color: $hover-dark-red;
  }
}
.whiteButton {
  background-color: white;
  border: none;
  color: $main-red;
  padding: 10px 50px;
  outline: none !important;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none !important;
  transition: all 0.3s linear;
  @media (max-width: 1200px) {
    padding: 10px 30px !important;
  }
  &:hover {
    text-decoration: none !important;
    color: $hover-dark-red;
  }
}
.addNewButton {
  width: 100%;
  height: 100%;
  min-height: 600px;
  background-color: transparent;
  color: $main-red;
  cursor: pointer;
  outline: none !important;
  border: 3px dashed rgba(0, 0, 0, 0.3);
  transition: all 0.3s linear;
  &:hover {
    text-decoration: none !important;
    color: $hover-dark-red;
  }
}
.progress-bar {
  position: relative;
  height: 20px;
  width: 100%;
  background-color: $rose;
}

.iconHolder {
  width: 100%;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  &.notification {
    max-width: none !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
  }
}

.filler {
  background: $main-red;
  height: 100%;
  transition: width 0.2s ease-in;
}
.latestCurrent {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h5 {
    font-size: 14px;
    padding-bottom: 16px;
  }
  h3 {
    padding-left: 10px !important;
  }
}

.notificationItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid rgba(216, 216, 216, 0.5);
  min-height: 80px;
  span {
    padding: 0 12px;
  }
}

.notificationNumber {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: $rose;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  left: -8px;
  z-index: 999;
  span {
    color: $main-red;
    font-size: 16px;
    font-weight: bold;
  }
}

// School
.schoolIconHolder {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $rose;
  opacity: 0.5;
  margin: 35px auto;
  &.supported {
    border: 1px solid #000;
  }
  img {
    width: 100px;
    height: auto;
  }
}
.addschoolIconHolder {
  background-color: rgb(159, 202, 238);
  position: relative;
  .plusIcon {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: $rose;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 20px;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
.slick-prev:before {
  content: '\f053' !important;
  font-family: FontAwesome !important;
  color: $main-red !important;
}
.slick-next:before {
  content: '\f054' !important;
  font-family: FontAwesome !important;
  color: $main-red !important;
}
.slick-initialized .slick-slide {
  margin-left: 20px !important;
}
.remove {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  box-shadow: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    padding-right: 5px;
  }
}
.shortSchoolDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .schoolName {
    flex: 1;
    padding-left: 50px;
    h3 {
      @media (max-width: 1200px) {
        font-size: 24px;
      }
    }
  }
}
.detailsHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  background-color: rgba(219, 219, 219, 0.5);
  background-image: url('../assets/img/project-details.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.imgHolder {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background-color: $rose;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Card management
.orderCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  span {
    color: $main-red;
    font-size: 24px;
    font-weight: lighter;
    padding-bottom: 10px;
  }
  p {
    font-size: 12px;
    font-weight: lighter;
  }
  img {
    margin: 20px 0;
  }
}

.cardDetails {
  display: flex;
  .imageCard {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cardInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      list-style-type: none;
      li {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-bottom: 5px;
        span {
          color: $main-red;
          padding: 0 10px;
        }
      }
    }
  }
}
.donation {
  list-style-type: none;
  padding: 0;
  li {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      font-size: 24px;
      padding-right: 10px;
    }
  }
}
.activeDonation,
.activeOption {
  color: $main-red;
}
.selectCardWrapper {
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  .dropdown {
    // flex: 0.6;
    img {
      width: 50px;
      height: auto;
    }
    button {
      background-color: transparent;
      border: none;
      box-shadow: none;
      outline: none;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 20px;
      height: 50px;
      display: flex;
      span {
        padding: 0 15px;
      }
    }
    .dropdown-menu {
      width: 100%;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        justify-content: space-around;
        align-items: center;
        padding: 10px 5px;
        cursor: pointer;
        &:hover {
          background-color: $rose;
          color: white;
        }
        img {
          width: 50px;
          height: auto;
        }
      }
    }
  }
}
.paymentMethod {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 10px 0;
    cursor: pointer;
  }
  img {
    width: 50px;
    height: 50px;
  }
}

// Modal
.react-modal-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 999999999;
}
.react-modal-overlay2 {
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 999999999;
}
.react-modal-content {
  // background-color: white;
  padding: 10px;
  position: relative;
  border-radius: 10px;
}
.closeBtn {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  button {
    cursor: pointer;
    border: none;
    box-shadow: none;
    background-color: transparent;
    outline: none !important;
  }
  i {
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;
  }
}
.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 50px;
  min-width: 500px;
  h3 {
    color: $main-red;
    padding-bottom: 20px;
    font-size: 24px;
  }
  p {
    font-size: 12px;
  }
  .phoneWrapper {
    display: flex;
    align-items: center;
    padding: 50px;
    i {
      font-size: 18px;
    }
    a {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.7);
      font-size: 12px;
      padding-left: 10px;
    }
  }
  .lockCardWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      padding-bottom: 10px;
    }
  }
  .pinInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    h1 {
      color: $main-red;
      padding: 10px 0;
    }
    h2 {
      padding-top: 10px;
      color: rgba(0, 0, 0, 0.7);
      font-size: 24px;
    }
  }
  h6 {
    position: absolute;
    bottom: 30px;
    font-size: 12px;
  }
}

.react-datepicker {
  border-radius: 0 !important;
  border: 1px solid black !important;
}
.react-datepicker__triangle {
  left: 50% !important;
  border-bottom-color: white !important;
  &::before {
    border-bottom-color: black !important;
  }
}
.react-datepicker__header {
  padding: 10px 10px 0 10px;
  background-color: white !important;
  border: none !important;
}
.react-datepicker__day-names .react-datepicker__day-name {
  font-weight: bold !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header {
  background-color: $gold !important;
  color: white !important;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-datepicker__navigation {
  top: 20px !important;
  outline: none !important;
}
// .react-datepicker-wrapper,
// .react-datepicker__input-container {
//   // width: 100%!important;
//   input {
//     font-size: 14px;
//     padding: 5px 20px 5px 20px;
//     outline: none;
//     margin-left: 80px;
//     border: 1px solid #979797;
//     margin-top: 20px;
//     text-align: center;
//     @media (max-width: 545px) {
//       padding: 0;
//     }
//   }
// }
// .datePickerHolder {
//   position: relative;
//   max-width: 350px;
//   @media (min-width: 1000px) {
//     margin-bottom: 10px;
//   }
//   p {
//     font-size: 12px;
//     position: absolute;
//     top: 7px;
//     left: 25px;
//     z-index: 99999;
//   }
//   i {
//     position: absolute;
//     top: 6px;
//     right: 25px;
//     z-index: 99999;
//     font-size: 21px;
//     color: $main-orange;
//   }
// }
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: $gold !important;
}
.transactionOptionDropdown {
  .transactionOptionBtn {
    display: flex;
    border: none;
    background-color: transparent;
    outline: none !important;
    box-shadow: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .dropdown-menu {
    border: 1px solid black;
    border-radius: 0;
    transform: translate3d(0px, 65px, 0px) !important;
    .arrow-up {
      width: 20px;
      height: 5px;
      border-bottom: solid 10px black;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
    .cover-arrow {
      width: 20px;
      height: 5px;
      border-bottom: solid 10px white;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      position: absolute;
      left: 50%;
      top: -9px;
      transform: translateX(-50%);
    }
  }
}

// Settings
.profilePictureHolder {
  display: flex;
  position: relative;
  .editPicture {
    position: absolute;
    top: 0;
    left: 120px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: rgb(236, 236, 236);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 9999;
  }
}
.loadingHolderEditPicture {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editor-canvas {
  width: 400px !important;
  height: 400px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.uploadButton {
  input[type='file'] {
    color: transparent;
    max-width: 100px;
    opacity: 0;
  }
  label {
    color: white;
    margin: 20px 0 0 20px;
    font-weight: 100;
    font-size: 14px;
    background: transparent;
    padding: 10px 25px;
    border: 1px solid white;
    border-radius: 50px;
    cursor: pointer;
  }
}

.profilePicture {
  width: 120px;
  height: 120px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  box-shadow: -3px 5px 20px 1px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;

  h1 {
    color: white;
    font-size: 100px;
  }
  img {
    width: 100%;
    height: auto;
  }
}
.profileName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 140px;
  word-break: break-word;
}

.settingsMenu {
  padding-top: 30px;
  span {
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    h5 {
      font-weight: bold;
    }
  }
  .overviewMenu {
    ul {
      list-style-type: none;
      padding: 0;
      li {
        width: 100%;
        text-align: left;
        cursor: pointer;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid black;
        a {
          padding: 15px 30px;
          text-decoration: none;
          color: black;
          display: flex;
          flex: 1;
          font-size: 18px;
          font-weight: lighter;
        }
      }
    }
  }
  .card-body {
    padding: 0;
    ul {
      padding-bottom: 20px;
      li {
        border: none;
        a {
          padding: 5px 50px;
        }
      }
    }
  }
  .panel-heading {
    position: relative;
  }
  .panel-heading a:after {
    font-family: FontAwesome;
    content: '\f054';
    font-size: 18px;
    transition: all 0.3s;
    position: absolute;
    right: 0;
    color: rgba(0, 0, 0, 0.7);
  }
  .panel-heading.activeTab a:after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
.pageHeader {
  background-color: #f8f7f7;
  padding: 16px 24px;
  margin-top: 30px;
  .pageTitle {
    font-weight: lighter;
    font-size: 24px;
    text-align: center;
  }
}
.note {
  font-size: 12px;
  margin: 30px 0;
  text-align: center;
  span {
    font-weight: bold;
  }
}
.settingsInput {
  width: 100%;
  background-color: white;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid black;
  position: relative;
}
.logDataRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0;
  border-bottom: 1px solid black;
  .status-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    .status-os {
      width: 50%;
      // margin-right: 80px;
    }
    .status-active {
      width: 100%;
    }
  }
  @media (max-width: 545px) {
    flex-direction: column;
    align-items: center;
    .status-os {
      width: auto !important;
      margin-right: 20px !important;
    }
    .status-active {
      margin-right: 5px !important;
      width: auto !important;
    }
    // span {
    //   margin: auto;
    //   margin-right: auto !important;
    // }
    i.fa-circle {
      display: none;
    }
  }
  > div {
    @media (max-width: 545px) {
      display: flex;
      // flex-direction: column;
      align-items: center;
    }
  }
  span {
    // flex: 1;
    // margin-right: 50px;
    font-size: 14px;
    padding: 10px 0;
    &:last-child {
      margin-right: 0;
    }
  }
}
.logDataRow:last-child {
  border: none;
}
.notificationRow {
  padding: 5px 0;
  h6 {
    color: $main-red;
    padding-bottom: 10px;
  }
  span {
    padding-bottom: 5px;
    font-weight: lighter;
  }
}
.paymentHolder {
  display: flex;
  align-items: center;
  span,
  button {
    font-size: 12px;
    font-weight: lighter;
  }
  button {
    font-size: 12px;
    font-weight: lighter;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 0;
  }
  img {
    width: 50px;
    height: 50px;
  }
  .centerRow {
    flex: 1;
    padding: 0 10px;
  }
  .buttonRow {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
    min-height: 65px;
  }
}
// Notification page
.notificationNav {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
#moreDropdown {
  transform: translate3d(-40px, 40px, 0px) !important;
  li {
    font-size: 12px;
    padding: 5px 10px;
  }
}
.showMoreNotification {
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none !important;
  cursor: pointer;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}
.mapHolder {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  z-index: 999;
}
.hiddenMap {
  display: none;
}
.visibleMap {
  display: flex;
}
.card > div {
  // height: 70vh!important;
  position: relative !important;
}
.pin {
  width: 60px;
  height: 60px;
  background-image: url('../assets/img/map-marker-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.mapButton {
  border: none;
  border-right: 1px solid black;
  background: transparent;
  outline: none !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mapSchoolDetails {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-top: none !important;
  background: white;
  padding: 0 16px;
  box-shadow: -2px 4px 10px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 9999999999;
  .nameHolder {
    flex: 1;
    padding-left: 20px;
    h3 {
      color: $main-red;
      font-size: 21px;
    }
    p {
      font-size: 12px;
      font-weight: lighter;
    }
  }
}
.prevNextBtn {
  border: none;
  background: transparent;
  cursor: pointer;
  box-shadow: none;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
// Footer
.footer {
  padding: 50px 0;
  background: $dark-gray;
  position: relative;
  z-index: 100;
  .link-title {
    color: white;
    // font-family: 'FilsonProBold';
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .link-holder {
    display: flex;
    flex-direction: column;
    .footer-link {
      // font-family: 'FilsonProBold';
      padding: 5px 0;
      // font-weight: bold;
      transition: all 0.3s linear;
      color: white;

      cursor: pointer;
      &:hover {
        color: $gold;
      }
    }
  }
  .social-icon {
    color: white;
    font-size: 36px;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
      color: $rose;
    }
  }
}
@media (max-width: 1200px) {
  .navbar-brand img {
    max-width: 150px !important;
    padding-bottom: 15px !important;
  }
}

.about-text-box {
  padding: 20px;
  border: 1px solid gray;
  p {
    font-size: 14px;
    color: $text-gray;
  }
}
.addNew {
  .react-datepicker-wrapper input,
  .react-datepicker__input-container input {
    border: none;
    border-bottom: 1px solid gray;
    text-align: left;
    padding-left: 10px;
  }
}

.admin-dashboard-widget-wrapper {
  .col-xl-6 {
    float: left;
  }
}
.widget-new-customers ul li {
  text-align: center;
}
.security-hidden {
  margin-top: 20px;
  width: 100%;
  display: none;

  transition: height 0.3s ease;
  overflow: hidden;
  height: 0;
  > div {
    margin-top: 20px;
  }
  &.open {
    height: auto;
    transition: height 0.3s ease;
    display: block !important;
  }
}
#file {
  opacity: 0;
  display: none;
}
.process-steps {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 50px;
  max-width: 60%;
  margin: 50px auto;
  .step {
    color: white !important;
    font-size: 20px;
    background: rgba($main-red, 0.5);
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    &.active {
      background: $main-red;
    }
  }
  hr {
    width: 100%;
    border-color: #343434;
    margin: auto;
  }
}
.setup-nav {
  list-style: none;
  display: flex;
  width: 100%;
  margin-top: 50px;
  padding: 20px 0 20px 0;
  justify-content: space-between;
  li {
    a {
      color: white;
    }
  }
}
.finalStep {
  background-color: transparent !important;
}
.profile-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.validation-message {
  font-size: 12px;
  color: $main-red;
  position: absolute;
  left: 0;
}

.input-group .validation-message {
  position: absolute;
  bottom: -36px;
  left: 0;
}
.back-button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: white !important;
  }
}
.input-group {
  width: 100%;
  background-color: white;
  padding: 10px;
  margin-top: 20px;

  &.round {
    border-radius: 100px;
  }

  &.centered {
    input {
      text-align: center;
    }
  }
}
.search-group {
  width: 100%;
  background-color: white;
  position: relative;
}
.css-1ep9fjw {
  cursor: pointer;
}
.checkbox-error {
  color: black;
  bottom: -20px;
  left: 30px;
  position: absolute;
}
.addressWrapper {
  max-height: 500px;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 30px;
  padding: 0 50px;
  a {
    color: $main-red;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid $main-red;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    &:hover {
      border-bottom: 1px solid $hover-dark-red;
      color: $hover-dark-red;
    }
  }
}
.no-address {
  color: white;
  font-size: 14px;
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: all 0.3s ease;
  &:hover {
    color: $rose;
  }
}
.custom-form-btn-container {
  width: 250px;
}
.modalDialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  min-width: 500px;
}
.project-donors-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  justify-content: flex-end;
  background: rgba(227, 216, 216, 0.7);
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.submitButtonWrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.change-submit-btn-wrapper {
  justify-content: flex-start;
}
.selectLabel label {
  left: -10px !important;
}
.profilePictureTopBar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }
}

.loading-overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.fileInput-addnewproject {
  opacity: 0;
  position: absolute;
  max-width: 160px;
  width: 100%;
  top: 5px;
  left: 30px;
  height: 50px;
  cursor: pointer !important;
}
.option-item {
  text-transform: capitalize !important;
}

.gift-aid {
  text-align: center;
  h1 {
    margin-bottom: 20px;
    color: $text-gray;
  }
  h2 {
    color: $main-red;
    text-align: center;
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  p {
    text-align: center;
    color: $text-gray;
    font-weight: 300;
  }
}
.gift-aid-button-container {
  max-width: 250px;
  display: block;
  margin: auto;
}
.noteInput {
  ::-webkit-input-placeholder {
    font-style: italic !important;
  }
  ::-moz-placeholder {
    font-style: italic !important;
  }
  ::-moz-placeholder {
    font-style: italic !important;
  }
  ::-ms-input-placeholder {
    font-style: italic !important;
  }
}
.schoolAdminLink {
  color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  text-decoration: none;
  -webkit-transition: all 0.3 linear;
  -o-transition: all 0.3 linear;
  transition: all 0.3 linear;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.schoolAdminNav {
  .nav-item:hover {
    cursor: pointer;
    .schoolAdminLink {
      color: $main-red;
    }
  }
}
.inviteHolder {
  input {
    padding: 11px 0 !important;
  }
}
.emptyInput {
  opacity: 0;
}
.disableBtn {
  opacity: 0.5;
  background-color: white;
  border: none;
  color: $main-red;
  padding: 10px 50px;
  outline: none !important;
  font-size: 14px;
  cursor: not-allowed;
  text-decoration: none !important;
  @media (max-width: 1200px) {
    padding: 10px 30px !important;
  }
}
#endDate {
  padding-left: 190px !important;
}
// PDL

.home-nav-container {
  position: relative;
  z-index: 10;
  background: $main-orange;
  .left-overlay {
    width: 40%;
    height: 70px;
    position: absolute;
    background: #efefef;
    left: 0;
    top: 0;
    @media (max-width: 992px) {
      display: none !important;
    }
  }
  .right-overlay {
    width: 40%;
    height: 70px;
    position: absolute;
    background: #efefef;
    right: 0;
    top: 0;
    display: none;
    @media (max-width: 992px) {
      display: none !important;
    }
  }
}
.user-info-wrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: 545px) {
    flex-direction: column;
    .button-holder {
      margin-top: 10px;
    }
  }
}
.personal--info {
  @media (max-width: 545px) {
    text-align: center;
  }
}
@media (max-width: 992px) {
  .home-main .col-lg-6 {
    margin-bottom: 20px;
  }
}
@media (max-width: 545px) {
  .homeProfilePicture {
    margin-bottom: 10px !important;
  }
  .lastLogin {
    justify-content: center;
  }
}

.home-nav-bar {
  // background: white;
  li {
    // flex: 1;
    // height: 70px;
    // display: flex;
    // justify-content: center;
    // margin-left: 0 !important;
    // margin-right: 0 !important;
    // background: $main-orange;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    i {
      color: white !important;
    }
    &.active {
      background: #efefef;
      // border: none !important;
      // border-bottom-left-radius: 0;
      i {
        color: $main-blue !important;
      }
      a {
        span {
          color: $main-blue !important;
          &::before {
            color: $main-blue !important;
          }
        }
      }
    }
    a {
      span {
        color: white !important;
      }
    }
  }
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: url(#idCSS);
}

.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid rgba(0,0,0,0.24);

  padding: 5px 10px 0 10px;
  background: white;
  p {
    font-family: 'FilsonProRegular';
    font-weight: bold;
    color: $dark-gray;
  }
  .right-icon {
    height: 10px;
  }
  &.disabled {
    p {
      color: #cecdd3;
    }
  }
}
.box-body {
  position: relative;
  // border-bottom-left-radius: 10px;
  // border-bottom-right-radius: 10px;
  padding: 20px 10px 20px 10px;
  box-sizing: border-box;
  background: white;
  &.disabled {
    background: #e6e6e6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    p {
      color: #9b9b9b;
      font-family: 'FilsonProBold';
      font-weight: bold;
      text-align: center;
    }
  }
  .go-to-inbox {
    position: absolute;
    right: 20px;
    bottom: 10px;
    color: $gold;
    border-bottom: 4px solid $gold;
  }
}
.show-transactions {
  display: flex;
  justify-content: space-around;
}
.transactionItem {
  // background: white;
  // // border-radius: 20px;
  padding-left: 25px;
  padding-right: 25px;
  // margin-bottom: 10px;
  // &.statements {
  //   border-radius: 50px !important;
  //   min-height: auto !important;
  //   padding-top: 0 !important;
  //   padding-bottom: 0 !important;
  //   min-height: 70px !important;
  // }

  .iconHolder {
    display: flex;
    // height: auto;
    min-width: 40px;
    background: transparent;
    // border: 1px solid $dark-gray;
  }
  .date-title-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    .title {
      color: $dark-gray;
      // font-weight: bold;
      text-align: left;
    }
    .date {
      color: $dark-gray;
      font-size: 12px;
      text-align: left;
    }
  }
  .amount-wrapper {
    color: $dark-gray;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
  }
}

.verify-modal .react-modal-content {
  border-radius: 10px;
  background: white;
}
.verification-container {
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  @media (max-width: 768px) {
    justify-content: center;
  }
}
.verification-separator {
  margin: 50px auto;
  @media (max-width: 1440px) {
    margin: 30px auto;
  }
}
.verification-form-container {
  max-width: 60%;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 100%;
    flex: 1;
  }
  .verification-button-wrapper {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .top-navigation {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 100px 20px 100px;
    @media (max-width: 1440px) {
      height: auto;
    }
    a {
      color: #ffaf8f;
      display: flex;
      align-items: center;
      font-size: 18px;
      img {
        margin-right: 10px;
      }
    }
  }

  .inner-container {
    max-width: 80%;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    h1,
    h5,
    p {
      color: white;
      text-align: center;
    }
    .profile-image {
      margin-bottom: 10px;
    }
  }
}
.verification-image {
  width: 45%;
  height: 100vh;
  background: url('../assets/img/login-bg-image-50.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  top: 0;
  right: 0;
  float: right;
  @media (max-width: 768px) {
    display: none;
    flex: 0;
  }
}
.verification-image-signup {
  width: 45%;
  height: 100vh;
  background: url('../assets/img/signup-bg.png') no-repeat center center;
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  float: right;
  @media (max-width: 768px) {
    display: none;
    flex: 0;
  }
}
.verification-image-order-card {
  width: 45%;
  height: 100vh;
  background: url('../assets/img/order-card-bg-image.png') no-repeat center
    center;
  background-size: cover;
  position: relative;
  top: 0;
  right: 0;
  float: right;
  @media (max-width: 768px) {
    display: none;
    flex: 0;
  }
}
.verification-image-2 {
  width: 40%;
  height: 100vh;
  background: url('../assets/img/worker-photo-2.jpg') no-repeat center center;
  background-size: cover;
  @media (max-width: 768px) {
    display: none;
    flex: 0;
  }
}
.verification-image-3 {
  width: 40%;
  height: 100vh;
  background: url('../assets/img/worker-photo-3.jpg') no-repeat center center;
  background-size: cover;
  @media (max-width: 768px) {
    display: none;
    flex: 0;
  }
}
.send-money-image {
  width: 40%;
  height: 100vh;
  background: url('../assets/img/send-money-big.jpg') no-repeat center center;
  background-size: cover;
  position: fixed;
  right: 0;
  @media (max-width: 768px) {
    display: none;
    flex: 0;
  }
}
.send-money-title {
  @media (max-width: 1440px) {
    margin-bottom: 10px !important;
  }
}
.link-card-bottom-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  .button-wrapper {
    margin-top: 40px;
    text-align: center;
  }
  @media (max-width: 545px) {
    margin-top: 0;
    .button-wrapper {
      margin-top: 10px;
    }
  }
}
.link-card-logo {
  margin: 10px auto !important;
}
.verification-input {
  width: 100%;
  background: white;
  border-radius: 50px;
  height: 40px;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  outline: none;
  color: black !important;
}
// label {
//   color: white;
//   margin: 0 0 0 20px;
//   font-weight: 100;
//   font-size: 14px;
// }
.input-wrapper {
  margin-bottom: 20px;
}
.tip-wrapper {
  font-size: 14;
  text-align: right !important;
  color: #ffaf8f;
  margin-top: 10px;
  position: relative;
  display: flex;
  justify-content: flex-end;

  .tip {
    position: absolute;
    max-width: 350px;
    padding: 10px;
    right: 0;
    display: none;
    transform: all 0.3s ease;
  }
  .tip-hover {
    width: 52%;
    &:hover {
      .tip {
        display: block;
      }
    }
    p {
      // text-align: left !important;
      font-size: 14px;
    }
  }
}
p.currency {
  // position: absolute;
  font-size: 72px;
  // left: 50%;
  // margin-left: -130px;
  color: #292929 !important;
  display: inline-block;
}
.amount-input {
  border: none;
  background: transparent;
  outline: none;
  font-size: 72px;
  text-align: center;
  color: #292929;

  &:focus {
    outline: none;
  }

  span:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: #292929 !important;
  }
  &::-moz-placeholder {
    color: #292929 !important;
  }
  &::-moz-placeholder {
    color: #292929 !important;
  }
  &::-ms-input-placeholder {
    color: #292929 !important;
  }
}
.send-money-modal .react-modal-content {
  background: transparent !important;
}

.show--password {
  color: white;
  opacity: 0.5;
  margin-top: -20px;
  align-self: flex-start;
  padding-left: 40px;
}

.success-send-modal {
  background: white;
  border-radius: 10px;
  width: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  .amount {
    color: $main-blue;
    font-size: 18px;
  }
  h3 {
    color: $main-blue;
    margin-top: 20px;
  }
  a {
    color: #606060;
  }
}

// MY ACCOUNT

.account-wrapper {
  background: #fff;
  border-radius: 10px;
  padding: 0;
  padding-top: 10px;
  margin-top: 30px;
  @media (max-width: 545px) {
    padding: 0;
  }
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 20px;
    margin-right: 20px;
    img {
      margin-right: 20px;
    }
    @media (max-width: 545px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: auto;

      img {
        margin-right: 0;
      }
      p {
        text-align: center;
      }
    }
  }
  .info {
    h4 {
      color: #d44e28;
      margin: 20px 0 10px 0;
      font-size: 20px;
    }
    p {
      color: #878787;
      span {
        color: #292929;
        margin-left: 10px;
      }
    }
    @media (max-width: 545px) {
      h4 {
        text-align: center;
      }
      p {
        text-align: center;
        span {
          display: block;
        }
      }
    }
  }
  .col-lg-4 {
    border-left: 1px solid #d8d8d8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 545px) {
      margin-top: 50px;
      border-left: 0;
    }
  }
}
@media (max-width: 545px) {
  #account-page-button-wrapper {
    justify-content: center !important;
    padding-top: 20px;
  }
}

// YELLOW HEADER
.yellow-header-container {
  background: $main-yellow;
  height: 66px;
  display: flex;
  align-items: center;
  @media (max-width: 545px) {
    height: auto;
    padding: 20px 0 20px 0;
    .yellow-header-wallet {
      justify-content: center;
      flex-direction: column;
    }
  }
}
.yellow-header-wallet {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px 0 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  p {
    color: white;
    font-weight: normal;
    font-family: 'FilsonProRegular';
    font-size: 14px;
    span {
      font-weight: bold;
      font-family: 'FilsonProBold';
      margin-left: 20px;
    }
  }
}
.yellow-header-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  .profile-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      color: white;
      font-size: 14px;
    }
    img {
      margin-right: 20px;
    }
  }
  .notifications-wrapper {
    p {
      color: white;
      font-size: 14px;
      i {
        font-size: 18px;
      }
      span {
        font-size: 30px;
        font-weight: bold;
        position: relative;
        top: 9px;
        left: -5px;
      }
    }
  }
}

// PROFILE SETTINGS PAGE

button.change-image {
  max-width: 180px;
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 10px;
}

.settings-nav-bar {
  li {
    flex: 1;
    height: 50px;
    display: flex;
    justify-content: center;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background: $main-orange;
    &:first-child {
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
    }
    i {
      color: white !important;
    }
    &.active {
      background: #fff;
      border: none !important;
      i {
        color: $main-blue !important;
      }
      a {
        span {
          color: $main-blue !important;
        }
      }
    }
    a {
      span {
        color: white !important;
      }
    }
  }
}

.settings-account-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.switch-button-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #dedede;
  margin-top: 30px;
}
.switch-inner-wrapper {
  display: flex;
  justify-content: flex-start;
  padding-left: 100px;
  margin-top: 30px;
  border-bottom: 1px solid rgb(222, 222, 222);
  padding-bottom: 20px;
  @media (max-width: 545px) {
    padding-left: 0;
  }
  .push-wrapper {
    margin-right: 100px;
    display: flex;
    align-items: center;
    @media (max-width: 545px) {
      margin-right: 20px;
    }
  }
}
.leaving-date-wrapper {
  width: 50%;
  min-height: 200px;
  @media (max-width: 545px) {
    width: 100%;
    min-height: auto;
  }
}
.date-label {
  width: 60px;
  position: absolute;
  top: 25px;
  left: 20px;
  z-index: 100;
  border-right: 1px solid #979797;
  padding-right: 10px;
  color: #979797;
}
.date-icon {
  position: absolute;
  right: 25px;
  top: 28px;
  font-size: 18px;
  color: #201d5b;
}

.card-home-tab {
  display: flex;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    .small-button {
      bottom: -70px !important;
      width: 200px;
      left: 50%;
      margin-left: -100px;
    }
    p.card-type {
      margin-top: 10px;
      text-align: center;
    }
    img {
      margin-right: 0 !important;
    }
  }
  p.card-type {
    color: gray;
    font-size: 14px;
    font-weight: normal;
    font-family: $normal;
    margin-bottom: 10px;
    span {
      font-family: $bold;
      font-weight: normal;
      font-size: 16px;
      color: $main-blue;
    }
  }
  img {
    margin-right: 30px;
  }
}
p.card-limit-text {
  font-size: 16px;
  margin-right: 20px;
  position: relative;
  color: gray;
  &:before {
    content: '';
    width: 9px;
    height: 9px;
    position: absolute;
    background: $main-blue;
    border-radius: 50%;
    left: -13px;
  }
  span {
    color: $main-blue;
    font-size: 16px;
  }
}
p.card-status-text {
  font-size: 16px;
  margin-right: 20px;
  position: relative;
  color: gray;
  &:before {
    content: '';
    width: 9px;
    height: 9px;
    position: absolute;
    background: #d44e27;
    border-radius: 50%;
    left: -13px;
  }
  span {
    color: $main-orange;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .card-home-tab-col-4 {
    margin-top: 100px !important;
    padding-top: 20px;
    border-top: 1px solid #d8d8d8;
    border-left: 0 !important;
  }
}

// CARD OVERVIEW TAB
.card-overview-col-4 {
  border-left: 0 !important;
  .button-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 291px;
    a {
      text-align: center;
    }
    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
}
.card-icon-wrapper {
  background: $main-orange;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
p.card-type {
  color: gray;
  font-size: 16px;
  font-weight: normal;
  font-family: $normal;
  margin-bottom: 10px;
  span {
    font-family: $bold;
    font-weight: normal;
    font-size: 16px;
    color: $main-blue;
    text-transform: uppercase;
  }
}
.card-overview-col-8 {
  .content-wrapper {
    display: flex;
    margin-top: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .text-wrapper {
    border-right: 1px solid #d8d8d8;
    padding-right: 10px;
    width: 30%;
    padding-top: 10px;
    @media (max-width: 768px) {
      width: 100%;
      border-right: 0 !important;
      padding-right: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid #d8d8d8;
    }
  }
  .switch-button-wrapper {
    width: 70%;
    padding-left: 50px;
    padding-top: 10px;
    flex-direction: column;
    @media (max-width: 768px) {
      padding-left: 0;
      width: 100%;
    }
  }
  .switch-holder {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.card-limits {
  max-width: 60%;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
  label {
    margin: 0;
    // font-weight: bold;
    font-family: $normal;
    color: $main-blue !important;
    font-size: 20px;
  }
  label:focus {
    color: $main-blue !important;
  }
  .edit-button {
    position: absolute;
    right: 0;
    top: 20px;
    cursor: pointer;
  }
  .switch-holder {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    p {
      font-family: $normal;
    }
  }
}

// REPORT LOST OR STOLEN

.report-stolen {
  padding-top: 50px;
  h1 {
    text-align: center;
    color: $main-blue;
  }
  h2 {
    text-align: center;
    color: $main-orange;
    font-size: 26px;
    font-family: $bold;
    img {
      width: 30px;
      margin-right: 10px;
    }
  }
  p {
    text-align: center;
    font-size: 18px;
    margin: 30px auto 30px auto;
    a {
      color: $main-orange;
    }
  }
  .switch-holder {
    max-width: 300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
}
.lock-card-modal {
  background: white;
  padding: 20px;
  width: 500px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    color: $dark-gray;
  }
  @media (max-width: 545px) {
    width: 100%;
  }
}

// SHOW PIN PAGE
.show-pin {
  width: 100vw;
  height: 100vh;
  // background: url('../assets/img/show-pin-bg.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px;
    background: rgba(255, 255, 255, 0.1);
    max-width: 800px;
    @media (max-width: 768px) {
      padding: 50px;
    }
  }
  .card-pin {
    margin-top: 50px;
  }
  .text-under {
    position: absolute;
    bottom: -50px;
    color: #ffba9f;
    font-weight: lighter;
    @media (max-width: 545px) {
      bottom: 0;
    }
  }
  .top-navigation {
    position: absolute;
    top: 70px;
    right: 70px;
    a {
      color: #ffaf8f;
    }
    @media (max-width: 768px) {
      top: 30px;
      right: 30px;
    }
  }
  .change-pin-text {
    color: white;
    font-size: 18px;
    text-align: center;
    margin-top: 100px;
    font-family: $normal;
    @media (max-width: 768px) {
      margin-top: 50px;
    }
  }
}

// NOTIFICATIONS PAGE

.notifications-header {
  display: flex;
  justify-content: flex-end;
  .notifications-dropdown-button {
    position: relative;
    background: white;
    border-radius: 50px;
    width: 160px;
    padding: 5px 15px;
    outline: none;
    border: none;
    i.fa-caret-down {
      position: absolute;
      right: 15px;
      top: 10px;
    }
  }
}

.notifications-col-12 {
  padding: 30px;
}
.notifications-dropdown {
  margin: 0 20px;
  .dropdown-menu {
    border-radius: 10px;
    border-color: #cfd1d7;
    li {
      color: rgba(32, 30, 91, 0.4);
      padding: 7px 10px !important;
      font-size: 13px !important;
    }
  }
  #moreDropdown {
    right: -38px;
  }
  .check-icon {
    position: absolute;
    right: 5px;
  }
}
.notifications-body {
  margin-top: 20px;
}

// STATEMENTS

.statements-dropdown {
  width: 100%;
  .statements-dropdown-button {
    background: transparent;
    color: white;
    padding: 5px 20px 5px 20px;
    width: 100%;
    border: 1px solid white;
    border-radius: 50px;
    text-align: left;
    outline: none;
    text-transform: capitalize;
    i {
      margin-top: 3px;
      float: right;
    }
  }
  ul.dropdown-menu {
    z-index: 999999;
    width: 100%;
    left: 40px !important;
    li {
      cursor: pointer;
    }
  }
}
.transactions-list {
  .app-list-item {
    // color: white;
    text-align: center;
  }
}

// .statements-transaction-wrapper {
//   padding: 0 15px;
//   margin-bottom: 20px;
//   border-right: 1px solid rgba($dark-gray, 0.3);
//   @media (max-width: 768px) {
//     padding: 0 30px;
//   }
//   @media (max-width: 545px) {
//     padding: 0 20px;
//   }
// }
// .statement-date {
//   background: transparent !important;
//   border-color: rgba($dark-gray, 0.6) !important;
//   color: rgba($dark-gray, 0.6) !important;
// }
// .statement-date .react-calendar {
//   color: black !important;
// }
// .statements-calendar-icon {
//   color: white !important;
//   top: 25px !important;
// }
// p.statement-date-text {
//   font-size: 14px;
//   position: absolute;
//   padding-right: 10px;
//   top: 27px;
//   left: 14px;
//   z-index: 0;
//   border-right: 1px solid white;
//   color: $dark-gray;
//   width: 45px;
// }
.form-group {
  margin-bottom: 0;
}
select.statements-select {
  background-color: transparent !important;
  outline: none;
  border: 1px solid white;
  color: white;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  border-radius: 50px;
  svg {
    fill: #fff;
  }
}
.custom-select:focus {
  border-color: white;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
// .datePickerHolder {
//   margin-bottom: 0 !important;
//   @media (max-width: 768px) {
//     max-width: 100%;
//   }
// }
.search-wrapper {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  i.fa-search {
    position: absolute;
    color: $dark-gray;
    top: 10px;
    left: 15px;
  }
  input {
    background: transparent;
    padding-left: 50px;
    border-bottom: 1px solid rgba($dark-gray, 0.6);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    outline: none;
    box-shadow: none;
    color: $dark-gray;
    height: 38px;
    // width: 100%;
    &::-moz-placeholder {
      color: rgba($dark-gray, 0.5) !important;
    }
    &::-webkit-input-placeholder {
      color: rgba($dark-gray, 0.5) !important;
    }
    &:-ms-input-placeholder {
      color: rgba($dark-gray, 0.5) !important;
    }
  }
}
// .filter-button-wrapper {
//   display: flex;
//   // flex-direction: column;
//   align-items: center;
//   justify-content: space-around;
//   flex-wrap: wrap;
//   padding-bottom: 20px;
//   .orange-button {
//     width: 200px;
//     margin-bottom: 20px;
//     max-width: 200px !important;
//     &:first-child {
//       margin-bottom: 10px;
//     }
//   }
//   @media (max-width: 545px) {
//     align-items: center;
//     .gold-button {
//       margin-bottom: 10px;
//     }
//   }
// }

.chart-top {
  .export {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .left {
      display: flex;
      img {
        margin-right: 10px;
      }
      p {
        margin-right: 10px;
        font-size: 18px;
        color: #a7a5c0;
      }
    }
    .right {
      display: flex;
      p {
        font-size: 18px;
        color: $main-blue;
        margin-right: 20px;
      }
      img {
        margin-right: 10px;
      }
    }
  }
  .chart-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    a {
      padding: 5px 20px 5px 20px;
      margin: 0 10px 0 10px;
      color: rgba($dark-gray, 0.5);
      &.active {
        color: $dark-gray !important;
        // background: $main-yellow;
        // border: none !important;
        border-bottom: 2px solid $gold;
      }
      @media (max-width: 545px) {
        padding: 5px 8px;
      }
    }
  }
}
.login-page {
  // background: url('../assets/img/login-\bg.jpg') no-repeat center center;
  background-size: cover;
}
.login-form .input-group {
  margin: 0;
  padding: 0;
}
.login-form .login-input-wrapper {
  // border: 1px solid #C3C3C3;
  padding: 10px 0;
  border-radius: 5px;
  // padding-left: 50px;
  margin-bottom: 20px;
  position: relative;
  img.input-icon {
    position: absolute;
    z-index: 1;
    top: 25px;
  }
}

.login-box {
  // background: white;
  border-radius: 10px;
  margin-right: 20px;
  max-width: 400px;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
  }
  .form-button-container {
    display: flex;
    justify-content: center;
  }
  .submitButtonWrapper {
    margin-top: 30px;
  }
  .orange-button {
    max-width: 200px;
    outline: none;
  }
  h4 {
    font-family: 'PlayfairDisplay';
    font-weight: bold;
    text-align: center;
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 50px;
    @media (max-width: 545px) {
      margin-bottom: 30px;
      font-size: 30px;
    }
  }
}
h4.login-title {
  font-family: 'PlayfairDisplay';
  font-weight: bold;
  text-align: center;
  @media (max-width: 545px) {
    margin-bottom: 30px;
    font-size: 30px;
  }
}
.login-holder-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  p {
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: lighter;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media (max-width: 768px) {
    margin-top: 50px;
  }
}
.login-page {
  .row {
    @media (max-width: 768px) {
      flex-wrap: wrap-reverse;
    }
  }
}

.login-logo {
  max-width: 200px;
  @media (max-width: 545px) {
    max-width: 100px;
  }
}
.inner.login-page {
  padding: 0;
  height: 100vh;
  background: white;
  @media (max-width: 545px) {
    height: auto;
    padding: 0;
  }
}

// WALLET BLOCK
.homeHeaderWrapper {
  .col-lg-5 {
    @media (max-width: 992px) {
      justify-content: center;
    }
    @media (max-width: 545px) {
      align-items: center;
    }
  }
  .personal--info {
    @media (max-width: 545px) {
      border-left: none !important;
    }
  }
  .balance {
    @media (max-width: 545px) {
      margin-left: auto;
      transform: translateX(-50%);
    }
  }
}
.wallet-top-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px 0 20px 0;
  margin-bottom: 30px;
  @media (max-width: 991px) {
    display: block !important;
  }
  @media (max-width: 545px) {
    flex-direction: column;
    padding: 0 10px;
  }
}
.wallet-buttons-wrapper {
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-left: 20px;

  @media (max-width: 545px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: auto;
    a {
      margin-bottom: 0 !important;
    }
  }
}

/*li#first.nav-item.active {
  &::before {
    position: absolute;
    min-width: 100%;
    background: #EFEFEF;
    height: 70px;
    left: -100%;
  }
} */

// HOME NAVBAR
#first {
  &.active {
    &::before {
      content: '';
      width: 50%;
      height: 70px;
      position: absolute;
      background: #efefef;
      left: -50%;
      top: 0;
      display: block;
    }
  }
}
#last {
  &.active {
    &::before {
      content: '';
      width: 50%;
      height: 70px;
      position: absolute;
      background: #efefef;
      right: -50%;
      top: 0;
      display: block;
    }
  }
}

.lang-select {
  width: 160px;
  > div {
    background: transparent;
    border: none;
  }
  div {
    outline: none;
    box-shadow: none;
  }
}

.app-text-input-datepicker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  .react-date-picker__wrapper {
    width: 100%;
    border: none;

    .react-date-picker__inputGroup {
      opacity: 0;
    }
  }
}

.MuiOutlinedInput-notchedOutline {
  border-color: gray !important;
}
#outlined-error {
  .MuiOutlinedInput-notchedOutline {
    border-color: $main-red !important;
  }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  color: black !important;
}

.flex-center-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cms-content {
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 10px;
    margin-top: 30px;
    font-weight: bold;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 18px;
  }

  p {
    margin: 5px;
  }

  p {
    margin: 10px 0;
  }

  .text-center {
    text-align: center;
  }

  .text-bold {
    font-weight: bold;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
  }

  table.bordered td,
  table.bordered th {
    border: 1px solid black;
  }

  table tr th {
    background-color: #b7b7b7;
    padding: 5px;
  }

  table tr td {
    padding: 5px;
  }

  table tr.noBorder th:first-child {
    border-right: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    display: flex;
    margin: 10px 0;
  }

  ul li .number {
    display: flex;
    font-weight: bold;
  }

  ul li .content {
    margin-left: 10px;
  }

  ul.bulletted {
    padding-left: 20px;
    margin-left: 10px;
    list-style: disc !important;
    list-style-type: disc !important;
  }

  ul.bulletted li {
    display: list-item;
    list-style: disc !important;
    list-style-type: disc !important;
  }
}
