@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?8jqqip');
  src:  url('fonts/icomoon.eot?8jqqip#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?8jqqip') format('truetype'),
    url('fonts/icomoon.woff?8jqqip') format('woff'),
    url('fonts/icomoon.svg?8jqqip#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-face-positive:before {
  content: "\e948";

}
.icon-face-negative:before {
  content: "\e949";

}
.icon-atm:before {
  content: "\e94a";

}
.icon-dashboard:before {
  content: "\e94b";

}
.icon-add-member:before {
  content: "\e900";

}
.icon-ask-member:before {
  content: "\e901";

}
.icon-card:before {
  content: "\e902";

}
.icon-card-verified:before {
  content: "\e903";

}
.icon-clipboard:before {
  content: "\e904";

}
.icon-ask-it:before {
  content: "\e905";

}
.icon-direct-debit:before {
  content: "\e906";

}
.icon-exclamation:before {
  content: "\e907";

}
.icon-exit:before {
  content: "\e908";

}
.icon-globe:before {
  content: "\e909";

}
.icon-happy-worker:before {
  content: "\e90a";

}
.icon-help:before {
  content: "\e90b";

}
.icon-internal-services:before {
  content: "\e90c";

}
.icon-int-transfer:before {
  content: "\e90d";

}
.icon-key:before {
  content: "\e90e";

}
.icon-location:before {
  content: "\e90f";

}
.icon-logged-in:before {
  content: "\e910";

}
.icon-logged-out:before {
  content: "\e911";

}
.icon-mail:before {
  content: "\e912";

}
.icon-member:before {
  content: "\e913";

}
.icon-members2:before {
  content: "\e914";

}
.icon-members3:before {
  content: "\e915";

}
.icon-online:before {
  content: "\e916";

}
.icon-paperwork:before {
  content: "\e917";

}
.icon-paperwork-verified:before {
  content: "\e918";

}
.icon-payroll:before {
  content: "\e919";

}
.icon-phone:before {
  content: "\e91a";

}
.icon-pin:before {
  content: "\e91b";

}
.icon-pound:before {
  content: "\e91c";

}
.icon-quick-easy:before {
  content: "\e91d";

}
.icon-report:before {
  content: "\e91e";

}
.icon-set-up:before {
  content: "\e91f";

}
.icon-shopping:before {
  content: "\e920";

}
.icon-statement1:before {
  content: "\e921";

}
.icon-support:before {
  content: "\e922";

}
.icon-terms-conditions:before {
  content: "\e923";

}
.icon-time:before {
  content: "\e924";

}
.icon-training:before {
  content: "\e925";

}
.icon-transfers:before {
  content: "\e926";

}
.icon-trusted:before {
  content: "\e927";

}
.icon-voice-prompt:before {
  content: "\e928";

}
.icon-wallet:before {
  content: "\e929";

}
.icon-bulb:before {
  content: "\e92a";

}
.icon-cog:before {
  content: "\e92b";

}
.icon-money-send:before {
  content: "\e92c";

}
.icon-languages:before {
  content: "\e92d";

}
.icon-travel:before {
  content: "\e92e";

}
.icon-target:before {
  content: "\e92f";

}
.icon-vegetable:before {
  content: "\e930";

}
.icon-fruit:before {
  content: "\e931";

}
.icon-stars:before {
  content: "\e932";

}
.icon-thumbs-up:before {
  content: "\e933";

}
.icon-refresh:before {
  content: "\e934";

}
.icon-money-exchange:before {
  content: "\e935";

}
.icon-statement-financial:before {
  content: "\e936";

}
.icon-home:before {
  content: "\e937";

}
.icon-ivr:before {
  content: "\e938";

}
.icon-camera:before {
  content: "\e939";

}
.icon-id-failed:before {
  content: "\e93a";

}
.icon-id-verified:before {
  content: "\e93b";

}
.icon-passport:before {
  content: "\e93c";

}
.icon-id:before {
  content: "\e93d";

}
.icon-online-portal:before {
  content: "\e93e";

}
.icon-smartphone:before {
  content: "\e93f";

}
.icon-social:before {
  content: "\e940";

}
.icon-warehouse3:before {
  content: "\e941";

}
.icon-warehouse2:before {
  content: "\e942";

}
.icon-warehouse1:before {
  content: "\e943";

}
.icon-farm:before {
  content: "\e944";

}
.icon-card-locked:before {
  content: "\e945";

}
.icon-card-unlocked:before {
  content: "\e946";

}
.icon-recruitment:before {
  content: "\e947";

}
